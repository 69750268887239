import React, { useState } from 'react';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import Layout from '../layouts/mainLayout';
import ContactForm from '../components/contactForm';
import Button from '../components/common/elements/button/MaterialButton';
import Modal from '../primespot-ui/components/modal';

import PriceQuoteForm from '../components/priceQuoteForm/priceQuoteForm';

import Styled from 'styled-components';
import { baseMixin } from '../styles/mixins';

export const query = graphql`
  query airDuctCleaningPageQuery {
    dirtyAirDuctImage: file(relativePath: { regex: "/dirty-air-duct.jpeg/" }) {
      childImageSharp {
        fixed(width: 300, height: 204) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

function PriceQuoteCallout() {
  const [showPriceQuote, setShowPriceQuote] = useState(false);

  return (
    <div>
      <div
        style={{
          borderLeft: '6px solid #ED8936',
          backgroundColor: '#FEEBC8',
          padding: '24px',
          textAlign: 'center',
        }}
      >
        <h4 style={{ fontSize: '24px' }}>
          Interested in having your air ducts cleaned?
        </h4>
        <p>
          Click the button below to get a price quote for our air duct cleaning
          services.
        </p>
        <p>
          <em>Hint: We're likely cheaper than our competitors.</em>
        </p>
        <Button onClick={() => setShowPriceQuote(true)}>Get quote</Button>
      </div>
      {showPriceQuote && (
        <Modal
          onClose={() => setShowPriceQuote(false)}
          header="Get a price quote for air duct cleaning"
        >
          <PriceQuoteForm service="air duct cleaning" />
        </Modal>
      )}
    </div>
  );
}

function AirDuctCleaning({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="Full air-duct cleaning service at an affordable price."
        />
        <title>Air-Duct Cleaning | Sci-Tech Carpet Cleaning</title>
      </Helmet>
      <AirDuctCleaningPage>
        <h1 className="underlined">Air-Duct Cleaning</h1>
        <h2>
          Air ducts are often the dirtiest part of the house and most people
          don't even have them cleaned
        </h2>
        <p>
          Seriously...Take a look at this photo of a dirty air duct for an
          example of how filthy air duct lines can get.
        </p>
        <div className="flex flex-col items-center">
          <Img fixed={data.dirtyAirDuctImage.childImageSharp.fixed} />
          <p
            style={{
              fontSize: '14px',
              color: '#999',
              marginTop: '0',
            }}
          >
            Image taken from{' '}
            <a
              style={{
                color: 'hsl(217,81%,53%)',
                fontSize: '14px',
              }}
              href="https://medium.com/@airductcleaning15/ducts-cleaning-how-dirty-air-ducts-can-impact-your-health-3ca8bd11fb57"
            >
              this Medium article
            </a>
            ...Filthy.
          </p>
        </div>
        <p>
          Can you imagine that dust flying around through your house whenever
          the fan in your HVAC system kicks on?
        </p>
        <PriceQuoteCallout />
        <p>
          Filthiness isn't the only reason to take care of your HVAC system,
          though. Your HVAC system is an essential part of your home. In some
          ways, it can even be called <em>the lungs of your house</em>
        </p>
        <p>
          Modern HVAC systems are pretty amazing pieces of equipment. With a
          full HVAC system including a heat pump, you can heat and cool your
          entire house. As an added bonus, when using a heat pump, you don’t
          even need to worry about carbon monoxide poisoning from the HVAC
          system.
        </p>
        <p>
          HVAC systems also help to ventilate your home and can filter allergens
          out of the air. They truly are essential to a healthy and comfortable
          home.
        </p>
        <p>
          Like nearly everything, though, they require some maintenance to work
          up to their full potential. HVAC and air duct cleaning is one useful
          maintenance operation.
        </p>
        <p>
          When your HVAC and air duct systems are too dirty, there are several
          potential issues that you may experience:
        </p>
        <ul>
          <li>
            <strong>Increased energy costs</strong>. Restricting air flow in the
            system can make the fan work longer and harder. This requires more
            energy. Dust build up on the HVAC coils also forces the HVAC system
            to work harder using more energy.
          </li>
          <li>
            <strong>Increased allergens in the air</strong>. If your air ducts
            have too much dust, mold, mildew, and other allergens, it is
            reasonable to assume that some of them will re-enter the air while
            the fan runs.
          </li>
          <li>
            <strong>Expensive repairs</strong>. For example, dirty evaporator
            coils are insulated by the dust and grime surrounding them. This
            makeshift insulation can prevent the warm air running over the coils
            from warming up the refrigerant in the system enough to prevent it
            from freezing. When this happens, the temperature of the compressor
            increases which can cause it to fail.
          </li>
          <li>
            <strong>Shorter lifespan of the HVAC system</strong>. All of the
            issues that can cause expensive repairs can also shorten the overall
            lifespan of the HVAC system.
          </li>
        </ul>
        <PriceQuoteCallout />
        <h3 className="text-center uppercase">What we clean</h3>
        <p>
          Unfortunately, we don’t clean every single piece of the HVAC system.
          For example, we don’t disassemble the heat pump and clean all the
          inner workings inside of it. If you are wanting this service, it would
          be best to work with a trained and certified HVAC repair technician
          who is comfortable working with a heat pump on this level.
        </p>
        <p>However, we do clean:</p>
        <ul>
          <li>
            <strong>Evaporator coil</strong>. This part is located within the
            air handler. We clean this coil as a normal part of our cleaning
            process. This is one of the most important pieces to clean to
            protect your system from breaking down.
          </li>
          <li>
            <strong>Condensate pan</strong>. This pan catches the moisture that
            drips off the evaporator coil. If it’s dirty and accessible, we will
            clean it as well.
          </li>
          <li>
            <strong>Air handler unit</strong>. We won’t disassemble the air
            handler unit or anything. But we will clean the dust, dirt, and
            grime out of it as best we can without disassembling.
          </li>
          <li>
            <strong>Air ducts</strong>. This is the main service that we offer.
            We use commercial-grade cleaning equipment to scrub and remove the
            dust and dirt from the air ducts. This is essential to remove as
            many allergens as possible from your home.
          </li>
          <li>
            <strong>Vent registers</strong>. The vent registers covering your
            air ducts likely have some dust caked onto them as well. We will
            clean them along with the air ducts.
          </li>
        </ul>
        <h3 className="text-center uppercase">Post-cleaning treatments</h3>
        <p>
          Along with the cleaning process, we also perform a process called
          “fogging.” This process involves misting a series of treatments into
          the air ducts. These treatments perform three important functions.
        </p>
        <ul>
          <li>
            <strong>Deodorization</strong>. We control odors within the air
            ducts with a deodorization treatment.
          </li>
          <li>
            <strong>Antimicrobial treatment</strong>. We kill mold and mildew
            within the air ducts. This is obviously essential to dealing with as
            many allergens as possible within your home.
          </li>
          <li>
            <strong>Sealant</strong>. We seal the air ducts which help to
            prevent particulates from blowing through the air.
          </li>
        </ul>
        <h3 className="text-center uppercase">Dryer vent cleaning</h3>
        <p>
          Dirty and clogged dryer vents are a fire hazard. It is absolutely
          necessary that your dryer vents be cleaned out at least once a year.
        </p>
        <p>We offer dryer vent cleaning as well.</p>
        <PriceQuoteCallout />
        <h3 className="text-center uppercase">Frequently Asked Questions</h3>
        <ul>
          <li>
            <strong>How often is air duct cleaning needed?</strong> As a general
            rule of thumb, air duct cleaning is needed every one to five years.
            How frequently depends upon factors such as whether or not you have
            pets, do you smoke in the house, are allergens a particular issue,
            have you engaged in any home remodeling projects that may have added
            dust into the environment, etc.
          </li>
          <li>
            <strong>How much does air duct cleaning cost?</strong> Cost varies
            depending upon the type of system you have, how many units you have,
            and how many duct lines are in the home. In general, though, our
            pricing is set to be lower than our top competitors’ pricing.
          </li>
          <li>
            <strong>Do you always clean the air handler?</strong> As a general
            rule, we clean the air handler along with the air duct cleaning
            process as a bonus. Exceptions will be made if the air handler isn’t
            in a reasonably accessible location.
          </li>
          <li>
            <strong>How long will an appointment take to complete?</strong> Most
            homes require anywhere between two and four hours to clean.
          </li>
          <li>
            <strong>What locations do you service?</strong> We service
            Blacksburg, Christiansburg, Radford, Roanoke and the surrounding
            areas in Virginia.
          </li>
        </ul>
        <div className="mb-8"></div>
        <ContactForm />
      </AirDuctCleaningPage>
    </Layout>
  );
}

const AirDuctCleaningPage = Styled.div`
  ${baseMixin};
`;

export default AirDuctCleaning;
